import styled from 'styled-components';
import Image from 'next/image';
import { Plus16 } from '@elfsight/icons';
import { Button, H1, P } from '@elfsight-universe/ui-common';
import {
  LayoutCenter,
  LayoutSection,
  ResendConfirmEmailCallout,
  LayoutInner,
  ChooseOccupationCallout,
  ChoosePreferredMonetizationCallout
} from '@components';
import { useWelcomeMessage } from '@modules/_app/utils';
import empty from '@public/assets/my-widgets-empty.png';
import { useDashboardCallout } from '@modules/dashboard/utils/use-dashboard-callout';

export function AppVirgin() {
  useWelcomeMessage('welcomeMessage');

  const activeCalloutAlias = useDashboardCallout();
  const activeCallout = {
    VERIFICATION_EMAIL: <ResendConfirmEmailCallout margin={[0, 40]} />,
    CHOOSE_OCCUPATION: <ChooseOccupationCallout margin={[0, 40]} />,
    CHOOSE_PREFERRED_MONETIZATION: (
      <ChoosePreferredMonetizationCallout margin={[0, 40]} />
    ),
    DEFAULT: null
  }[activeCalloutAlias || 'DEFAULT'];

  return (
    <LayoutSection>
      <LayoutInner>
        <LayoutCenter spacer={[0, 0]} padding={0}>
          {activeCallout}
          <Image src={empty} width={240} height={200} alt="" />

          <H1 gb mt={20}>
            Create your first widget
          </H1>

          <Text alignCenter mb={20}>
            To create a widget, you need to select an app in our catalog. Then
            use one of the ready-made templates or configure your widget from
            scratch.
          </Text>

          <Button
            href="/catalog?create=true"
            variation="accentPrimary"
            leftIcon={Plus16}
            large
          >
            Create Widget
          </Button>
        </LayoutCenter>
      </LayoutInner>
    </LayoutSection>
  );
}

const Text = styled(P)`
  max-width: 500px;
`;
